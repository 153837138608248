<template>
  <div class="container_margin">
    <div class="card p-3">
      <h5><b>{{ "Upload Contract Document" }}</b></h5>

      <span class="my-2">
        <b>
          Contract Document
          <span
            id="tooltip-target-1"
            class="text-danger cursor-pointer"
          >*</span>
        </b>
        <b-tooltip
          target="tooltip-target-1"
          triggers="hover"
          placement="top"
          variant="secondary"
          id="tooltip1"
          title="Required"
        ></b-tooltip>
      </span>
      <div class="row mb-2">
        <div class="col">
          <input
            id="contract-input"
            class="form-control"
            name="Contract File"
            v-validate="'required'"
            type="file"
            accept=".pdf,.docx"
            @change="handleFileChange($event, 'contract')"
          />
          <small class="font-12 mx-1">
            <b-icon icon="info-circle" />
            Please upload a PDF or Document file only
            <br>
          </small>
          <small class="text-danger">
            {{ errors.first("Contract File") }}
          </small>
        </div>
        <div class="col">
          <input
            class="form-control"
            type="text"
            id="fileName"
            name="Contract File Name"
            v-validate="'required'"
            placeholder="Contract File Name"
            v-model.trim="filenames[0]"
          />
          <small class="font-12 mx-1">
            <b-icon icon="info-circle" />
            Please rename your Contract file
            <br>
          </small>
          <small class="text-danger">
            {{ errors.first("Contract File Name") }}
          </small>
        </div>
      </div>

      <span class="my-2">
        <b>
          Guideline Document (Optional)
          <!-- <span
            id="tooltip-target-2"
            class="text-danger cursor-pointer"
          >*</span> -->
        </b>
        <!-- <b-tooltip
          target="tooltip-target-2"
          triggers="hover"
          placement="top"
          variant="secondary"
          id="tooltip1"
          title="Required"
        ></b-tooltip> -->
      </span>
      <div class="row mb-3">
        <div class="col">
          <input
            id="guideline-input"
            class="form-control"
            name="Guideline File"
            type="file"
            accept=".pdf,.docx"
            @change="handleFileChange($event, 'guideline')"
          />
          <small class="font-12 mx-1">
            <b-icon icon="info-circle" />
            Please upload a PDF or Document file only
            <br>
          </small>
          <small class="text-danger">
            {{ errors.first("Guideline File") }}
          </small>
        </div>
        <div class="col">
          <input
            id="fileName"
            class="form-control"
            type="text"
            placeholder="Guideline File Name (Optional)"
            v-model.trim="filenames[1]"
          />
          <small class="font-12 mx-1">
            <b-icon icon="info-circle" />
            Please rename your Guideline file
          </small>
        </div>
      </div>

      <b>
        Jurisdiction
        <span
          id="tooltip-target-2"
          class="text-danger cursor-pointer"
        >*</span>
      </b>
      <b-tooltip
        target="tooltip-target-2"
        triggers="hover"
        placement="top"
        variant="secondary"
        id="tooltip1"
        title="Required"
      ></b-tooltip>
      <div class="row mt-2">
        <div class="col">
          <b-form-select
            v-model="jurisdiction"
            :class="{ 'text-muted': jurisdiction === '' }"
            class="form-control"
            name="Jurisdiction"
            v-validate="'required'"
          >
            <b-form-select-option value="" disabled selected>
              {{ "Please Select a Jurisdiction" }}
            </b-form-select-option>
            <b-form-select-option
              class="mt-2 text-softblack"
              v-for="(juris, index) in jurisdictions"
              :key="index"
              :value="juris"
            >
              {{ juris }}
            </b-form-select-option>
          </b-form-select>
          <small class="text-danger">
            {{ errors.first("Jurisdiction") }}
          </small>
        </div>

        <div class="col">
          <button
            id="buttonFile"
            class="btn btn-primary float-right"
            @click="handleFileUpload"
          >
            <div class="row">
              <div class="col-1">
                <span class="material-icons-outlined">file_upload</span>
              </div>
              <div class="col">
                {{ "Upload File" }}
              </div>
            </div>
          </button>
        </div>
      </div>
    </div>

    <h5 class="mt-3">
      <b>{{ `Contract Review Result${ searched_data.length > 1 ? "s" : "" }` }}</b>
    </h5>

    <div class="row mt-2 mb-3">
      <div
        v-if="searched_data.length !== 0 || searchQuery.length !== 0"
        class="col-5"
      >
        <span id="searchCaseLaw" class="input-group">
          <input
            v-model="searchQuery"
            type="search"
            class="form-control"
            placeholder="Search by statement"
            aria-label="Search by statement"
            @input="onInput"
            @keyup.enter="searchClients"
          />
          <span
            class="input-group-text cursor-pointer"
            @click="searchClients"
          >
            <b-icon icon="search" font-scale="1.2" />
          </span>
        </span>
      </div>

      <div v-if="searched_data.length !== 0 && rows > 10" class="col-7">
        <b-pagination
          class="mb-0 float-right"
          v-model="currentPage"
          :total-rows="rows"
          :per-page="perPage"
          aria-controls="my-table"
          last-number
        ></b-pagination>
      </div>
    </div>

    <table class="table card border shadow-sm p-2 rounded table-card">
      <thead>
        <tr>
          <th scope="col" width="6%">{{ "ID" }}</th>
          <th scope="col" width="30%">{{ "Contract Document" }}</th>
          <th scope="col" class="text-center" width="15%">
            {{ "Jurisdiction" }}
          </th>
          <th scope="col" class="text-center" width="15%">
            {{ "Uploaded Date" }}
          </th>
          <th
            scope="col"
            class="text-center"
            width="15%"
            style="padding-right: 15px"
          >
            {{ "Uploaded By" }}
          </th>
          <th
            scope="col"
            class="text-center"
            style="padding-right: 25px"
            width="20%"
          >
            {{ "Action" }}
          </th>
        </tr>
      </thead>

      <tbody>
        <PageLoader
          v-if="pageLoading"
          :loading="pageLoading"
        ></PageLoader>

        <div
          v-else-if="searched_data.length === 0"
          class="container_margin"
        >
          <div class="my-2 m-auto" style="width: 200px">
            <img src="@/assets/noData.png" alt="No Data Image" />
          </div>
          <p class="text-center text-blue font-weight-bold">
            {{ "No Data, Kindly start your search" }}
          </p>
        </div>

        <tr v-else :key="index" v-for="(item, index) in searched_data">
          <td width="6%">
            <p>{{ item.id }}</p>
          </td>
          <td width="30%">
            <p>{{ item.file_name }}</p>
          </td>
          <td class="text-center" width="15%">
            <p>
              {{
                item.jurisdiction
                  ? (item.jurisdiction === "USA"
                    ? "United States of America"
                    : item.jurisdiction)
                  : "N/A"
              }}
            </p>
          </td>
          <td width="15%">
            <p class="text-muted text-center">
              {{ item.created_at | formatDate }}
            </p>
          </td>
          <td width="15%">
            <p class="text-muted text-center font-weight-bold">
              {{ item.creator.name | username }}
            </p>
          </td>
          <td
            width="20%"
            class="text-center"
          >
            <button
              v-if="item.completedPerCentage >= 100"
              style="width: 100px;"
              class="btn btn-primary"
              @click="openCase(item.id)"
            >
              {{ "Open" }}
            </button>

            <button
              v-else
              class="btn btn-warning-outlined h-100"
              @click="$toast.warning('Still in progress.')"
            >
              <span><b>Reviewing:</b></span> &nbsp;
              <span class="no-wrap">
                {{ item.completedPerCentage.toFixed(2) }} %
                <b-spinner label="Spinning" class="spinner" />
              </span>
            </button>
          </td>
        </tr>
      </tbody>
    </table>

    <div style="height: 2.5rem;">
      <b-pagination
        v-if="searched_data.length !== 0 && rows > 10"
        class="float-right mt-3"
        v-model="currentPage"
        :total-rows="rows"
        :per-page="perPage"
        aria-controls="my-table"
        last-number
      ></b-pagination>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import PageLoader from "../../components/PageLoader.vue";
import ContractReview from "@/store/ContractReview.js";
import { Authenticate, createFormData, groupValidateInput, loopUpdate, validateInput } from "../../store/utils";

export default {
  components: {
    PageLoader,
  },

  data() {
    return {
      rows: 0,
      perPage: 10,
      currentPage: 1,
      pageLoading: true,
      file_in_upload: false,
      searchQuery: "",
      searched_data: [],

      filenames: ["", ""],
      jurisdiction: "",
      jurisdictions: [
        "United States of America",
        "United Kingdom",
        "Australia",
        "Canada",
        "New Zealand",
        "Singapore",
        "Malaysia",
      ],

      loop: false,
    };
  },

  computed: {
    party() {
      return {
        "Discloser": "disclosing",
        "Recipient": "receiving",
      }[this.$route.params.id];
    },
  },

  methods: {
    onInput() {
      if (this.searchQuery.length === 0) {
        this.searchClients();
      }
    },

    searchClients() {
      this.currentPage = 1;
      this.pageLoading = true;
      this.searched_data = [];

      const queryObj = { page: this.currentPage };
      if (this.searchQuery.length !== 0) {
        queryObj.file_name = this.searchQuery;
      }
      
      this.$router.push({
        query: queryObj,
      });
      this.loadData();
    },

    toastError(flag) {
      this.$toast.error({
        "file": "Please upload a .pdf or .docx file",
        "empty": "Please fill in all required fields",
        "invalid": "Please input a valid file name\nWhich should not include: '/' '(' ')' ','"
      }[flag]);
    },

    handleFileChange(event, type) {
      const File = validateInput(event.target.files[0], "file", ["pdf", "docx"]);
      const ID = type == "contract" ? 0 : 1;

      if (!File[0] && File[1] !== "empty") {
        document.getElementById(`${type}-input`).value = "";
        this.toastError(File[1]);
      }

      this.$set(this.filenames, ID, File[0] ? File[1].name : "");
    },

    clearFileInput(flag) {
      if (flag == "contract" || flag == "all") {
        document.getElementById(`contract-input`).value = "";
        this.filenames[0] = "";
      }
      if (flag == "guideline" || flag == "all") {
        document.getElementById(`guideline-input`).value = "";
        this.filenames[1] = "";
      }
    },

    handleFileUpload() {
      this.$validator.validateAll();

      const Results = groupValidateInput([
        [this.jurisdiction, "jurisdiction"],
        [this.filenames[0], "string", ["filename"]],
        [this.filenames[1], "string", ["filename"]],
      ]);

      const Files = [
        document.getElementById("contract-input").files[0],
        document.getElementById("guideline-input").files[0],
      ];

      if (
        !Files[0] ||// no required contract file
        (!Results[1][0] && Results[1][1] === "empty") || // empty contract file name
        !Results[0][0] // no jurisdiction
      ) {
        this.toastError("empty");
        return;
      } else if (Results.findIndex(x => !x[0]) > 0 && Results[2][1] !== "empty") {
        this.toastError(Results.find(x => !x[0])[1]);
        return;
      }
      this.file_in_upload = true;
      const Data = {
        "jurisdiction": Results[0][1],
        "case_file": Files[0],
        "file_name": Results[1][1],
        "party": this.party,
        "location_id": 1,
        "language": "en",
      };

      if (Files[1]) { 
        Data["guidelines"] = Files[1];
      }
      if (Results[2][0]) {
        Data["guidelines_name"] = Results[2][1];
      }
      this.clearFileInput("all");
      this.jurisdiction = "";
      this.pageLoading = true;

      ContractReview.UploadFile(
          createFormData(Data)
      )
          .then((response) => {
              Authenticate(this);
              this.$toast.success(response.data.message);
              this.currentPage = 1;
              this.searched_data = [];
              this.loadData();
          })
          .catch((error) => {
              console.log(error);
              this.pageLoading = false;
          });
},
    openCase(data) {
      this.$router.push({
        name: `ContractDocument${this.$route.params.id}`,
        params: {
          id: data,
        },
      });
    },

    loadData() {
      const me = this;
      function processData(data) {
        const DATA = data.data.data;
        const insert = me.searched_data.length > 0;
        me.pageLoading = false;
        me.rows = DATA.total;
        DATA.data.forEach(e => {
          const ID = me.searched_data.findIndex(old => old.id === e.id);

          if (ID > -1) {
            me.$set(me.searched_data, ID, e);
          } else if (insert) {
            me.searched_data.unshift(e);
          } else {
            me.searched_data.push(e)
          }

          if (e.completedPerCentage < 100) me.loop = true;
        })
      }

      loopUpdate(
        this,
        ContractReview.GetContractReview,
        `&party=${this.party}&page=${this.currentPage}${
          this.searchQuery ? `&file_name=${this.searchQuery}` : ""
        }`,
        processData
      )
    },
  },

  watch: {
    currentPage(newPage, oldPage) {
      if (newPage != this.$route.query.page) {
        this.pageLoading = true;
        this.searched_data = [];

        if (this.searchQuery.length !== 0) {
          this.$router.push({
            query: { page: newPage, file_name: this.searchQuery },
          });
        } else {
          this.$router.push({ query: { page: newPage } });
        }
        clearTimeout(this.loop);
        this.loadData();
      }
    },
  },

  created() {
    const page = this.$route.query.page;
    if (page) {
      this.currentPage = parseInt(page, 10);
      this.searched_data = [];
    }

    const file_name = this.$route.query.file_name;
    if (file_name) {
      this.searchQuery = file_name;
    }
    
    this.loadData();
  },

  destroyed() {
    clearTimeout(this.loop);
  },
};
</script>

<style scoped>
/* table */
.table {
  border: none;
  background: white;
  table-layout: fixed;
}

tbody {
  display: block;
}

thead {
  display: table;
  width: 100%;
  table-layout: fixed;
  overflow-x: auto;
  border-bottom: 0.1vw solid #ddd !important;
  background: white;
}

tbody tr {
  display: table;
  width: 100%;
  table-layout: fixed;
  overflow-x: auto;
  border: none;
  background: white;
}

td {
  border-bottom: 0.001px solid #ddd;
  border-top: none;
  vertical-align: top;
}

th {
  border: none;
  color: #094890;
}

.container_margin {
  padding: 2rem;
}

.spinner {
  color: #f08000 !important;
}

.spinner-border {
  --bs-spinner-width: 1rem;
  --bs-spinner-height: 1rem;
  --bs-spinner-vertical-align: -0.15em;
  --bs-spinner-border-width: 0.2em;
}
</style>
